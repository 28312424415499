import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./Footer.css";

export default function Footer() {
  const [scrollableModal, setScrollableModal] = useState(false);

  return (
    <MDBFooter className="text-center text-lg-start text-muted foooter">
      <section className="foooter foooter__main">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase padding-left textwhite fw-bold mb-4">
                Przydatne linki
              </h6>
              <p
                onClick={() => setScrollableModal(!scrollableModal)}
                className="textwhite onclick"
              >
                Polityka prywatności
              </p>

              <MDBModal
                show={scrollableModal}
                setShow={setScrollableModal}
                tabIndex="-1"
              >
                <MDBModalDialog scrollable>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>POLITYKA PRYWATNOŚCI</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={() => setScrollableModal(!scrollableModal)}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className="privatepolicy">
                        <>
                          <h6 className="paragraph">§1 Definicje</h6>
                          <ul>
                            <li>
                              Serwis - serwis internetowy "Poradnia
                              psychologiczna Paulina Tokarczyk" działający pod
                              adresem www.paulinatokarczyk.pl
                            </li>
                            <li>
                              Serwis zewnętrzny - serwisy internetowe partnerów,
                              usługodawców lub usługobiorców współpracujących z
                              Administratorem
                            </li>
                            <li>
                              Administrator Serwisu / Danych - Administratorem
                              Serwisu oraz Administratorem Danych (dalej
                              Administrator) jest firma "Pracownia
                              psychologiczna Paulina Tokarczyk", prowadząca
                              działalność pod adresem: Barcice 21, 33-334
                              Barcice, o nadanym numerze identyfikacji
                              podatkowej (NIP): 7343587043, o nadanym numerze
                              KRS: 387170819, świadcząca usługi drogą
                              elektroniczną za pośrednictwem Serwisu
                            </li>
                            <li>
                              Użytkownik - osoba fizyczna, dla której
                              Administrator świadczy usługi drogą elektroniczną
                              za pośrednictwem Serwisu.
                            </li>
                            <li>
                              Urządzenie - elektroniczne urządzenie wraz z
                              oprogramowaniem, za pośrednictwem którego
                              Użytkownik uzyskuje dostęp do Serwisu
                            </li>
                            <li>
                              Cookies (ciasteczka) - dane tekstowe gromadzone w
                              formie plików zamieszczanych na Urządzeniu
                              Użytkownika
                            </li>
                            <li>
                              RODO - Rozporządzenie Parlamentu Europejskiego i
                              Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                              sprawie ochrony osób fizycznych w związku z
                              przetwarzaniem danych osobowych i w sprawie
                              swobodnego przepływu takich danych oraz uchylenia
                              dyrektywy 95/46/WE (ogólne rozporządzenie o
                              ochronie danych)
                            </li>
                            <li>
                              Dane osobowe - oznaczają informacje o
                              zidentyfikowanej lub możliwej do zidentyfikowania
                              osobie fizycznej („osobie, której dane dotyczą”);
                              możliwa do zidentyfikowania osoba fizyczna to
                              osoba, którą można bezpośrednio lub pośrednio
                              zidentyfikować, w szczególności na podstawie
                              identyfikatora takiego jak imię i nazwisko, numer
                              identyfikacyjny, dane o lokalizacji, identyfikator
                              internetowy lub jeden bądź kilka szczególnych
                              czynników określających fizyczną, fizjologiczną,
                              genetyczną, psychiczną, ekonomiczną, kulturową lub
                              społeczną tożsamość osoby fizycznej
                            </li>
                            <li>
                              Przetwarzanie - oznacza operację lub zestaw
                              operacji wykonywanych na danych osobowych lub
                              zestawach danych osobowych w sposób
                              zautomatyzowany lub niezautomatyzowany, taką jak
                              zbieranie, utrwalanie, organizowanie,
                              porządkowanie, przechowywanie, adaptowanie lub
                              modyfikowanie, pobieranie, przeglądanie,
                              wykorzystywanie, ujawnianie poprzez przesłanie,
                              rozpowszechnianie lub innego rodzaju
                              udostępnianie, dopasowywanie lub łączenie,
                              ograniczanie, usuwanie lub niszczenie;
                            </li>
                            <li>
                              Ograniczenie przetwarzania - oznacza oznaczenie
                              przechowywanych danych osobowych w celu
                              ograniczenia ich przyszłego przetwarzania
                            </li>
                            <li>
                              Profilowanie - oznacza dowolną formę
                              zautomatyzowanego przetwarzania danych osobowych,
                              które polega na wykorzystaniu danych osobowych do
                              oceny niektórych czynników osobowych osoby
                              fizycznej, w szczególności do analizy lub prognozy
                              aspektów dotyczących efektów pracy tej osoby
                              fizycznej, jej sytuacji ekonomicznej, zdrowia,
                              osobistych preferencji, zainteresowań,
                              wiarygodności, zachowania, lokalizacji lub
                              przemieszczania się
                            </li>
                            <li>
                              Zgoda - zgoda osoby, której dane dotyczą oznacza
                              dobrowolne, konkretne, świadome i jednoznaczne
                              okazanie woli, którym osoba, której dane dotyczą,
                              w formie oświadczenia lub wyraźnego działania
                              potwierdzającego, przyzwala na przetwarzanie
                              dotyczących jej danych osobowych
                            </li>
                            <li>
                              Naruszenie ochrony danych osobowych - oznacza
                              naruszenie bezpieczeństwa prowadzące do
                              przypadkowego lub niezgodnego z prawem
                              zniszczenia, utracenia, zmodyfikowania,
                              nieuprawnionego ujawnienia lub nieuprawnionego
                              dostępu do danych osobowych przesyłanych,
                              przechowywanych lub w inny sposób przetwarzanych
                            </li>
                            <li>
                              Pseudonimizacja - oznacza przetworzenie danych
                              osobowych w taki sposób, by nie można ich było już
                              przypisać konkretnej osobie, której dane dotyczą,
                              bez użycia dodatkowych informacji, pod warunkiem
                              że takie dodatkowe informacje są przechowywane
                              osobno i są objęte środkami technicznymi i
                              organizacyjnymi uniemożliwiającymi ich przypisanie
                              zidentyfikowanej lub możliwej do zidentyfikowania
                              osobie fizycznej
                            </li>
                            <li>
                              Anonimizacja - Anonimizacja danych to
                              nieodwracalny proces operacji na danych, który
                              niszczy / nadpisuje "dane osobowe" uniemożliwiając
                              identyfikację, lub powiązanie danego rekordu z
                              konkretnym użytkownikiem lub osobą fizyczną.
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §2 Inspektor Ochrony Danych
                          </h6>
                          <p>
                            Na podstawie Art. 37 RODO, Administrator nie powołał
                            Inspektora Ochrony Danych.
                          </p>
                          <p>
                            W sprawach dotyczących przetwarzania danych, w tym
                            danych osobowych, należy kontaktować się
                            bezpośrednio z Administratorem.
                          </p>
                        </>
                        <>
                          <h6 className="paragraph">
                            §3 Rodzaje Plików Cookies
                          </h6>
                          <ul>
                            <li>
                              Cookies wewnętrzne - pliki zamieszczane i
                              odczytywane z Urządzenia Użytkownika przez system
                              teleinformatyczny Serwisu
                            </li>
                            <li>
                              Cookies zewnętrzne - pliki zamieszczane i
                              odczytywane z Urządzenia Użytkownika przez systemy
                              teleinformatyczne Serwisów zewnętrznych. Skrypty
                              Serwisów zewnętrznych, które mogą umieszczać pliki
                              Cookies na Urządzeniach Użytkownika zostały
                              świadomie umieszczone w Serwisie poprzez skrypty i
                              usługi udostępnione i zainstalowane w Serwisie
                            </li>
                            <li>
                              Cookies sesyjne - pliki zamieszczane i odczytywane
                              z Urządzenia Użytkownika przez Serwis podczas
                              jednej sesji danego Urządzenia. Po zakończeniu
                              sesji pliki są usuwane z Urządzenia Użytkownika.
                            </li>
                            <li>
                              Cookies trwałe - pliki zamieszczane i odczytywane
                              z Urządzenia Użytkownika przez Serwis do momentu
                              ich ręcznego usunięcia. Pliki nie są usuwane
                              automatycznie po zakończeniu sesji Urządzenia
                              chyba że konfiguracja Urządzenia Użytkownika jest
                              ustawiona na tryb usuwanie plików Cookie po
                              zakończeniu sesji Urządzenia.
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §4 Rodzaje Plików Cookies
                          </h6>
                          <ul>
                            <li>
                              Mechanizmy składowania i odczytu plików Cookie -
                              Mechanizmy składowania, odczytu i wymiany danych
                              pomiędzy Plikami Cookies zapisywanymi na
                              Urządzeniu Użytkownika a Serwisem są realizowane
                              poprzez wbudowane mechanizmy przeglądarek
                              internetowych i nie pozwalają na pobieranie innych
                              danych z Urządzenia Użytkownika lub danych innych
                              witryn internetowych, które odwiedzał Użytkownik,
                              w tym danych osobowych ani informacji poufnych.
                              Przeniesienie na Urządzenie Użytkownika wirusów,
                              koni trojańskich oraz innych robaków jest także
                              praktycznie niemożliwe.
                            </li>
                            <li>
                              Cookie wewnętrzne - zastosowane przez
                              Administratora pliki Cookie są bezpieczne dla
                              Urządzeń Użytkowników i nie zawierają skryptów,
                              treści lub informacji mogących zagrażać
                              bezpieczeństwu danych osobowych lub bezpieczeństwu
                              Urządzenia z którego korzysta Użytkownik.
                            </li>
                            <li>
                              Cookie zewnętrzne - Administrator dokonuje
                              wszelkich możliwych działań w celu weryfikacji i
                              doboru partnerów serwisu w kontekście
                              bezpieczeństwa Użytkowników. Administrator do
                              współpracy dobiera znanych, dużych partnerów o
                              globalnym zaufaniu społecznym. Nie posiada on
                              jednak pełnej kontroli nad zawartością plików
                              Cookie pochodzących od zewnętrznych partnerów. Za
                              bezpieczeństwo plików Cookie, ich zawartość oraz
                              zgodne z licencją wykorzystanie przez
                              zainstalowane w serwisie Skrypty, pochodzących z
                              Serwisów zewnętrznych, Administrator nie ponosi
                              odpowiedzialności na tyle na ile pozwala na to
                              prawo. Lista partnerów zamieszczona jest w dalszej
                              części Polityki Prywatności.
                            </li>
                            <li>
                              Zagrożenia po stronie Użytkownika - Administrator
                              stosuje wszelkie możliwe środki techniczne w celu
                              zapewnienia bezpieczeństwa danych umieszczanych w
                              plikach Cookie. Należy jednak zwrócić uwagę, że
                              zapewnienie bezpieczeństwa tych danych zależy od
                              obu stron w tym działalności Użytkownika.
                              Administrator nie bierze odpowiedzialności za
                              przechwycenie tych danych, podszycie się pod sesję
                              Użytkownika lub ich usunięcie, na skutek świadomej
                              lub nieświadomej działalność Użytkownika, wirusów,
                              koni trojańskich i innego oprogramowania
                              szpiegującego, którymi może jest lub było
                              zainfekowane Urządzenie Użytkownika. Użytkownicy w
                              celu zabezpieczenia się przed tymi zagrożeniami
                              powinni stosować się do zasad korzystania z sieci
                              internet.
                            </li>
                            <li>
                              Przechowywanie danych osobowych - Administrator
                              zapewnia, że dokonuje wszelkich starań, by
                              przetwarzane dane osobowe wprowadzone dobrowolnie
                              przez Użytkowników były bezpieczne, dostęp do nich
                              był ograniczony i realizowany zgodnie z ich
                              przeznaczeniem i celami przetwarzania.
                              Administrator zapewnia także, że dokonuje
                              wszelkich starań w celu zabezpieczenia posiadanych
                              danych przed ich utratą, poprzez stosowanie
                              odpowiednich zabezpieczeń fizycznych jak i
                              organizacyjnych.
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §5 Cele do których wykorzystywane są pliki Cookie
                          </h6>
                          <ul>
                            <li>
                              Usprawnienie i ułatwienie dostępu do Serwisu
                            </li>
                            <li>Personalizacja Serwisu dla Użytkowników</li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §6 Cele przetwarzania danych osobowych
                          </h6>
                          <p>
                            Dane osobowe dobrowolnie podane przez Użytkowników
                            są przetwarzane w jednym z następujących celów:
                          </p>
                          <ul>
                            <li>Realizacji usług elektronicznych:</li>
                            <li>
                              Komunikacji Administratora z Użytkownikami w
                              sprawach związanych z Serwisem oraz ochrony danych
                            </li>
                            <li>
                              Zapewnienia prawnie uzasadnionego interesu
                              Administratora
                            </li>
                          </ul>
                          <p>
                            Dane o Użytkownikach gromadzone anonimowo i
                            automatycznie są przetwarzane w jednym z
                            następujących celów:
                          </p>
                          <ul>
                            <li>
                              Zapewnienia prawnie uzasadnionego interesu
                              Administratora
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §7 Pliki Cookies Serwisów zewnętrznych
                          </h6>
                          <p>
                            Administrator w Serwisie wykorzystuje skrypty
                            javascript i komponenty webowe partnerów, którzy
                            mogą umieszczać własne pliki cookies na Urządzeniu
                            Użytkownika. Pamiętaj, że w ustawieniach swojej
                            przeglądarki możesz sam decydować o dozwolonych
                            plikach cookies jakie mogą być używane przez
                            poszczególne witryny internetowe. Poniżej znajduje
                            się lista partnerów lub ich usług zaimplementowanych
                            w Serwisie, mogących umieszczać pliki cookies:
                          </p>
                          <p>
                            Usługi świadczone przez podmioty trzecie są poza
                            kontrolą Administratora. Podmioty te mogą w każdej
                            chwili zmienić swoje warunki świadczenia usług,
                            polityki prywatności, cel przetwarzania danych oraz
                            sposów wykorzystywania plików cookie.
                          </p>
                        </>
                        <>
                          <h6 className="paragraph">
                            §8 Rodzaje gromadzonych danych
                          </h6>
                          <p>
                            Serwis gromadzi dane o Użytkownikach. Cześć danych
                            jest gromadzona automatycznie i anonimowo, a część
                            danych to dane osobowe podane dobrowolnie przez
                            Użytkowników w trakcie zapisywania się do
                            poszczególnych usług oferowanych przez Serwis.
                          </p>
                          <p>
                            Dane gromadzone podczas wysłania formularza
                            kontaktowego
                          </p>
                          <ul>
                            <li>Imię</li>
                            <li>Nazwisko</li>
                            <li>Numer telefonu</li>
                            <li>Adres e-mail</li>
                            <li>
                              Dane osobiste, potrzebne do wykonania działań
                              zawodowych Administratora
                            </li>
                          </ul>
                          <p>
                            Część danych (bez danych identyfikujących) może być
                            przechowywana w plikach cookies. Cześć danych (bez
                            danych identyfikujących) może być przekazywana do
                            dostawcy usług statystycznych.
                          </p>
                        </>
                        <>
                          <h6 className="paragraph">
                            §9 Dostęp do danych osobowych przez podmioty trzecie
                          </h6>
                          <p>
                            Co do zasady jedynym odbiorcą danych osobowych
                            podawanych przez Użytkowników jest Administrator.
                            Dane gromadzone w ramach świadczonych usług nie są
                            przekazywane ani odsprzedawane podmiotom trzecim.
                          </p>
                          <p>
                            Dostęp do danych (najczęściej na podstawie Umowy
                            powierzenia przetwarzania danych) mogą posiadać
                            podmioty, odpowiedzialne za utrzymania
                            infrastruktury i usług niezbędnych do prowadzenia
                            serwisu tj.:
                          </p>
                          <ul>
                            <li>
                              Firmy hostingowe, świadczące usługi hostingu lub
                              usług pokrewnych dla Administratora
                            </li>
                            <li>
                              Firmy mailowe, świadczące usługi mailingu lub
                              usług pokrewnych dla Administratora
                            </li>
                          </ul>
                          <p>
                            Powierzenie przetwarzania danych osobowych - Usługi
                            Hostingu, VPS lub Serwerów Dedykowanych
                          </p>
                          <p>
                            Administrator w celu prowadzenia serwisu korzysta z
                            usług zewnętrznego dostawcy hostingu, VPS lub
                            Serwerów Dedykowanych - Hostinger. Wszelkie dane
                            gromadzone i przetwarzane w serwisie są
                            przechowywane i przetwarzane w infrastrukturze
                            usługodawcy zlokalizowanej w obrębie gramic Unii
                            Europejskiej. Istnieje możliwość dostępu do danych
                            wskutek prac serwisowych realizowanych przez
                            personel usługodawcy. Dostęp do tych danych reguluje
                            umowa zawarta pomiędzy Administratorem a
                            Usługodawcą.
                          </p>
                          <p>
                            Powierzenie przetwarzania danych osobowych - Usługi
                            mailingu.
                          </p>
                          <p>
                            Administrator w celu prowadzenia serwisu korzysta z
                            usług zewnętrznego dostawcy mailingu - GMAIL oraz
                            EMAILJS. Wszelkie dane gromadzone i przetwarzane w
                            serwisie są przechowywane i przetwarzane w
                            infrastrukturze usługodawcy zlokalizowanej w obrębie
                            gramic Unii Europejskiej. Istnieje możliwość dostępu
                            do danych wskutek prac serwisowych realizowanych
                            przez personel usługodawcy. Dostęp do tych danych
                            reguluje umowa zawarta pomiędzy Administratorem a
                            Usługodawcą.
                          </p>
                        </>
                        <>
                          <h6 className="paragraph">
                            §10 Sposób przetwarzania danych osobowych{" "}
                          </h6>
                          <p>
                            Dane osobowe podane dobrowolnie przez Użytkowników:
                          </p>
                          <ul>
                            <li>
                              Dane osobowe nie będą przekazywane poza Unię
                              Europejską, chyba że zostały opublikowane na
                              skutek indywidualnego działania Użytkownika (np.
                              wprowadzenie komentarza lub wpisu), co sprawi, że
                              dane będą dostępne dla każdej osoby odwiedzającej
                              serwis.
                            </li>
                            <li>
                              Dane osobowe nie będą wykorzystywane do
                              zautomatyzowanego podejmowania decyzji
                              (profilowania).
                            </li>
                            <li>
                              Dane osobowe nie będą odsprzedawane podmiotom
                              trzecim.
                            </li>
                          </ul>
                          <p>
                            Dane anonimowe (bez danych osobowych) gromadzone
                            automatycznie:
                          </p>
                          <ul>
                            <li>
                              Dane anonimiwe (bez danych osobowych) nie będą
                              przekazywane poza Unię Europejską.
                            </li>
                            <li>
                              Dane anonimiwe (bez danych osobowych) nie będą
                              wykorzystywane do zautomatyzowanego podejmowania
                              decyzji (profilowania).
                            </li>
                            <li>
                              Dane anonimiwe (bez danych osobowych) nie będą
                              odsprzedawane podmiotom trzecim.
                            </li>
                          </ul>
                        </>

                        <>
                          <h6 className="paragraph">
                            §11 Podstawy prawne przetwarzania danych osobowych
                          </h6>
                          <p>
                            Serwis gromadzi i przetwarza dane Użytkowników na
                            podstawie:
                          </p>
                          <ul>
                            <li>
                              Rozporządzenia Parlamentu Europejskiego i Rady
                              (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                              ochrony osób fizycznych w związku z przetwarzaniem
                              danych osobowych i w sprawie swobodnego przepływu
                              takich danych oraz uchylenia dyrektywy 95/46/WE
                              (ogólne rozporządzenie o ochronie danych)
                              <ul>
                                <li>
                                  art. 6 ust. 1 lit. a osoba, której dane
                                  dotyczą wyraziła zgodę na przetwarzanie swoich
                                  danych osobowych w jednym lub większej liczbie
                                  określonych celów
                                </li>
                                <li>
                                  art. 6 ust. 1 lit. b przetwarzanie jest
                                  niezbędne do wykonania umowy, której stroną
                                  jest osoba, której dane dotyczą, lub do
                                  podjęcia działań na żądanie osoby, której dane
                                  dotyczą, przed zawarciem umowy
                                </li>
                                <li>
                                  art. 6 ust. 1 lit. f przetwarzanie jest
                                  niezbędne do celów wynikających z prawnie
                                  uzasadnionych interesów realizowanych przez
                                  administratora lub przez stronę trzecią
                                </li>
                              </ul>
                            </li>
                            <li>
                              Ustawa z dnia 10 maja 2018 r. o ochronie danych
                              osobowych (Dz.U. 2018 poz. 1000)
                            </li>
                            <li>
                              Ustawa z dnia 16 lipca 2004 r. Prawo
                              telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)
                            </li>
                            <li>
                              Ustawa z dnia 4 lutego 1994 r. o prawie autorskim
                              i prawach pokrewnych (Dz. U. 1994 Nr 24 poz. 83)
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §12 Okres przetwarzania danych osobowych
                          </h6>
                          <p>
                            Dane osobowe podane dobrowolnie przez Użytkowników:
                          </p>
                          <p>
                            Co do zasady wskazane dane osobowe są przechowywane
                            wyłącznie przez okres świadczenia Usługi w ramach
                            Serwisu przez Administratora. Są one usuwane lub
                            anonimizowane w okresie do 30 dni od chwili
                            zakończenia świadczenia usług.
                          </p>
                          <p>
                            Wyjątek stanowi sytuacja, która wymaga
                            zabezpieczenia prawnie uzasadnionych celów dalszego
                            przetwarzania tych danych przez Administratora. W
                            takiej sytuacji Administrator będzie przechowywał
                            wskazane dane, od czasu żądania ich usunięcia przez
                            Użytkownika, nie dłużej niż przez okres 3 lat w
                            przypadku naruszenia lub podejrzenia naruszenia
                            zapisów regulaminu serwisu przez Użytkownika
                          </p>
                          <p>
                            Dane anonimowe (bez danych osobowych) gromadzone
                            automatycznie:
                          </p>
                          <p>
                            Anonimowe dane statystyczne, niestanowiące danych
                            osobowych, są przechowywane przez Administratora w
                            celu prowadzenia statystyk serwisu przez czas
                            nieoznaczony
                          </p>
                        </>
                        <>
                          <h6 className="paragraph">
                            §13 Prawa Użytkowników związane z przetwarzaniem
                            danych osobowych
                          </h6>
                          <p>
                            Serwis gromadzi i przetwarza dane Użytkowników na
                            podstawie:
                          </p>
                          <li>
                            Prawo dostępu do danych osobowych - Użytkownikom
                            przysługuje prawo uzyskania dostępu do swoich danych
                            osobowych, realizowane na żądanie złożone do
                            Administratora
                          </li>
                          <li>
                            Prawo do sprostowania danych osobowych -
                            Użytkownikom przysługuje prawo żądania od
                            Administratora niezwłocznego sprostowania danych
                            osobowych, które są nieprawidłowe lub / oraz
                            uzupełnienia niekompletnych danych osobowych,
                            realizowane na żądanie złożone do Administratora
                          </li>
                          <li>
                            Prawo do usunięcia danych osobowych - Użytkownikom
                            przysługuje prawo żądania od Administratora
                            niezwłocznego usunięcia danych osobowych,
                            realizowane na żądanie złożone do Administratora.
                            Administrator zastrzega sobie prawo wstrzymania
                            realizacji żądania usunięcia danych w celu ochrony
                            prawnie uzasadnionego interesu Administratora.
                          </li>
                          <li>
                            Prawo do ograniczenia przetwarzania danych osobowych
                            - Użytkownikom przysługuje prawo ograniczenia
                            przetwarzania danych osobowych w przypadkach
                            wskazanych w art. 18 RODO, m.in. kwestionowania
                            prawidłowość danych osobowych, realizowane na
                            żądanie złożone do Administratora
                          </li>
                          <li>
                            Prawo do przenoszenia danych osobowych -
                            Użytkownikom przysługuje prawo uzyskania od
                            Administratora, danych osobowych dotyczących
                            Użytkownika w ustrukturyzowanym, powszechnie
                            używanym formacie nadającym się do odczytu
                            maszynowego, realizowane na żądanie złożone do
                            Administratora
                          </li>
                          <li>
                            Prawo wniesienia sprzeciwu wobec przetwarzania
                            danych osobowych - Użytkownikom przysługuje prawo
                            wniesienia sprzeciwu wobec przetwarzania jego danych
                            osobowych w przypadkach określonych w art. 21 RODO,
                            realizowane na żądanie złożone do Administratora
                          </li>
                          <li>
                            Prawo wniesienia skargi - Użytkownikom przysługuje
                            prawo wniesienia skargi do organu nadzorczego
                            zajmującego się ochroną danych osobowych.
                          </li>
                        </>
                        <>
                          <h6 className="paragraph">
                            §14 Kontakt do Administratora
                          </h6>
                          <p>
                            Z Administratorem można skontaktować się w jeden z
                            poniższych sposobów
                          </p>
                          <ul>
                            <li>
                              Adres pocztowy - Pracownia psychologiczna Paulina
                              Tokarczyk, Barcice 21, 33-334 Barcice
                            </li>
                            <li>
                              Adres poczty elektronicznej -
                              paulina.tokarczyk93@gmail.com
                            </li>
                            <li>Połączenie telefoniczne - +48 660 053 872</li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">§15 Wymagania Serwisu</h6>
                          <ul>
                            <li>
                              Ograniczenie zapisu i dostępu do plików Cookie na
                              Urządzeniu Użytkownika może spowodować
                              nieprawidłowe działanie niektórych funkcji
                              Serwisu.
                            </li>
                            <li>
                              Administrator nie ponosi żadnej odpowiedzialności
                              za nieprawidłowo działające funkcje Serwisu w
                              przypadku gdy Użytkownik ograniczy w jakikolwiek
                              sposób możliwość zapisywania i odczytu plików
                              Cookie.
                            </li>
                          </ul>
                        </>
                        <>
                          <h6 className="paragraph">
                            §16 Zmiany w Polityce Prywatności
                          </h6>
                          <ul>
                            <li>
                              Administrator zastrzega sobie prawo do dowolnej
                              zmiany niniejszej Polityki Prywatności bez
                              konieczności informowania o tym Użytkowników w
                              zakresie stosowania i wykorzystywania danych
                              anonimowych lub stosowania plików Cookie.
                            </li>
                            <li>
                              Administrator zastrzega sobie prawo do dowolnej
                              zmiany niniejszej Polityki Prywatności w zakresie
                              przetwarzania Danych Osobowych, o czym poinformuje
                              Użytkowników posiadających dane kontaktowe, za
                              pośrednictwem poczty elektronicznej w terminie do
                              7 dni od zmiany zapisów. Dalsze korzystanie z
                              usług oznacza zapoznanie się i akceptację
                              wprowadzonych zmian Polityki Prywatności
                            </li>
                            <li>
                              Wprowadzone zmiany w Polityce Prywatności będą
                              publikowane na tym odnośniku Serwisu.
                            </li>
                            <li>
                              Wprowadzone zmiany wchodzą w życie z chwilą ich
                              publikacji.
                            </li>
                          </ul>
                        </>
                      </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        color="info"
                        onClick={() => setScrollableModal(!setScrollableModal)}
                      >
                        Zamknij
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase padding-left textwhite fw-bold mb-4">
                Kontakt
              </h6>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <span className="textwhite">paulina.tokarczyk93@gmail.com</span>
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />{" "}
                <span className="textwhite"> +48 660 053 872</span>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 foooter__copyrights"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <p className="textwhite">
          © 2023 Copyright:{" "}
          <a href="/" className="text-reset fw-bold">
            TERAPIA
          </a>
        </p>
        <p className="textwhite">
          Strona stworzona przez:{" "}
          <a
            className="text-reset fw-bold"
            href="https://www.linkedin.com/in/stanek-michal/"
            target="_blank"
            rel="noreferrer"
          >
            STANEK MICHAŁ
          </a>
        </p>
      </div>
    </MDBFooter>
  );
}
