import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import "./Kontakt.css";
import React, { useState, 
  // useEffect,
   useRef } from "react";
import emailjs from "@emailjs/browser";
import Popup from "../components/Popup/Popup";

export default function Kontakt() {
  // POPUP
  const [buttonPopup, setButtonPopup] = useState(false);

  // EMAIL SEND
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_baj39m3",
        "template_9x76k29_test",
        form.current,
        "LKBwOSv-TLJSrrHwP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // FORM VALIDATION
  const initialValues = {
    user_name: "",
    user_surname: "",
    user_phone: "",
    user_email: "",
    user_message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    // if (Object.keys(formErrors).length === 0) {
    //   sendEmail(e);
    // };
  };

  // useEffect(() => {
  //   console.log(formErrors);
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     console.log(formValues);
  //   }
  // }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!values.user_name) {
      errors.user_name = "Podaj imię.";
    }
    if (!values.user_surname) {
      errors.user_surname = "Podaj nazwisko.";
    }
    if (!values.user_phone) {
      errors.user_phone = "Podaj numer telefonu.";
    }
    if (!values.user_email) {
      errors.user_email = "Podaj poprawny adres email.";
    } else if (!regex.test(values.user_email)) {
      errors.user_email = "Podaj poprawny adres email.";
    }
    if (!values.user_message) {
      errors.user_message = "Napisz wiadomość.";
    }
    return errors;
  };

  // DO FORM STAFF
  const doFormStaff = (e) => {
    handleSubmit(e);
    if (Object.keys(formErrors).length === 0) {
      sendEmail(e);
    }
  };

  return (
    <div className="contact-page">
      
      {/* <Popup trigger={true}>
        <div className="popup-div-text">
          <p className="popup-text">Email został poprawnie wysłany.</p>
          <p className="popup-text">Za niedługo się skontaktuję.</p>
        </div>
      </Popup> */}

      {Object.keys(formErrors).length === 0 && isSubmit ? (
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <div className="popup-div-text">
            <p className="popup-text">Email został poprawnie wysłany.</p>
            <p className="popup-text">Za niedługo się skontaktuję.</p>
          </div>
        </Popup>
      ) : (
        // <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
        <></>
      )}
      <h3 className="pad-bottom">KONTAKT</h3>
      <form ref={form} className="row size-page" onSubmit={doFormStaff}>
        <MDBInput
          label="Imię"
          id="typeText"
          // className="gap10"
          className="zindexinput"
          type="text"
          name="user_name"
          value={formValues.user_name}
          onChange={handleChange}
        />
        <p>{formErrors.user_name}</p>

        <MDBInput
          label="Nazwisko"
          id="typeText"
          // className="gap10"
          className="zindexinput"
          type="text"
          name="user_surname"
          value={formValues.user_surname}
          onChange={handleChange}
        />
        <p>{formErrors.user_surname}</p>

        <MDBInput
          label="Numer telefonu"
          id="typePhone"
          type="tel"
          // className="gap10"
          className="zindexinput"
          name="user_phone"
          value={formValues.user_phone}
          onChange={handleChange}
        />
        <p>{formErrors.user_phone}</p>

        <MDBInput
          label="Email"
          id="typeEmail"
          // className="gap10"
          className="zindexinput"
          type="email"
          name="user_email"
          value={formValues.user_email}
          onChange={handleChange}
        />
        <p>{formErrors.user_email}</p>

        <MDBTextArea
          label="Wiadomość"
          id="textAreaExample"
          // className="gap10"
          className="zindexinput"
          name="user_message"
          rows="4"
          value={formValues.user_message}
          onChange={handleChange}
        />
        <p>{formErrors.user_message}</p>

        <MDBBtn
          color="info"
          onClick={() => setButtonPopup(true)}
          type="submit"
          value="Wyślij wiadomość"
          className="zindexinput"
        >
          {" "}
          Wyślij wiadomość{" "}
        </MDBBtn>
        <p className="policy-agree">
          *Wysyłając wiadomość akceptujesz politykę prywatności.
        </p>
      </form>
    </div>
  );
}
