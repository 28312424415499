import "./Cennik.css";

export default function Cennik() {
  return (
    <div className="cennik">
      <h3 className="cennik__title">CENNIK</h3>
      <ul className="cennik__list">
        <li className="cennik__list-element">
          Konsultacja psychologiczna (50 min) – 220 zł
        </li>
        <li className="cennik__list-element">
          Psychoterapia indywidualna (50 min*) – 220 zł
        </li>
        <li className="cennik__list-element">Warsztaty – cena ustalana indywidualnie</li>
      </ul>
      <p className="cennik__info">
        *W indywidualnych przypadkach, po wcześniejszym uzgodnieniu, planowany
        czas sesji może być dłuższy, co wiąże się ze wzrostem opłaty.
      </p>
    </div>
  );
}
