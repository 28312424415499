import "./App.css";
import React from "react";

import Footer from "./components/Footer/Footer";

import Main from "./pages/Main";
import Omnie from "./pages/Omnie";
import Cennik from "./pages/Cennik";
import Kontakt from "./pages/Kontakt";

import imageParallax from "./assets/imageParallax.png";
import { Parallax } from "react-parallax";
import logo from "./assets/logo.png";

function App() {
  return (
    <>
      <div className="app__container">
        <header>
          <Parallax
            className="parallax parallax__img"
            bgImage={imageParallax}
            blur={{ min: -4, max: 6 }}
          >
            <div className="parallax__div">
              <div className="logo">
                <img src={logo} alt="logo" className="logoimg" />
              </div>
              <p className="parallax__text-welcome border-botside">
                Witaj na mojej stronie!
              </p>
              <h1 className="parallax__text">
                Pracownia psychologiczna Paulina Tokarczyk
              </h1>
              <div className="parallax__callme border-botside">
                <h1 className="parallax__callme-call border-topside">
                  Zadzwoń
                </h1>
                <h3>+48 660 053 872</h3>
              </div>
            </div>
          </Parallax>
        </header>
        {/* <main> */}
          {/* <section> */}
            <Main />
          {/* </section> */}
          <p className="border-botside2 width"></p>
          {/* <section> */}
            <Omnie />
          {/* </section> */}
          <p className="border-botside2 width"></p>
          {/* <section> */}
            <Cennik />
          {/* </section> */}
          <p className="border-botside2 width"></p>
          {/* <section> */}
            <Kontakt />
          {/* </section> */}
        {/* </main> */}
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
