import "./Omnie.css";
import myPhoto from "../assets/myphoto.png";

export default function Omnie() {
  return (
    <div className="aboutme">
      <div className="aboutme-primary">
        <div className="aboutme-primary-text">
          <p className="abouttext">
          Jestem psycholożką, certyfikowaną psychoterapeutką poznawczo-behawioralną (Certyfikat Psychoterapeuty Poznawczo-Behawioralnego Polskiego Towarzystwa Terapii Poznawczej i Behawioralnej nr 1389), członkiem Polskiego Towarzystwa Terapii Poznawczej i Behawioralnej, a także certyfikowaną Edukatorką Pozytywnej Dyscypliny z akredytacją Positive Discipline Association.
          </p>
          <p className="abouttext">
          Jestem absolwentką psychologii ze specjalizacją psychologia zdrowia i choroby w Instytucie Psychologii Stosowanej Uniwersytetu Jagiellońskiego. Ukończyłam też Studium Pedagogiczne Uniwersytetu Jagiellońskiego.
          </p>
        </div>
        <div className="aboutme-photo">
          <img src={myPhoto} alt="that is me" className="photome" />
        </div>
      </div>
      <div className="aboutme-secondary">
        <p className="abouttext">
        Doświadczenie zawodowe zdobywałam między innymi w takich miejscach jak: Oddział Psychiatrii Szpitala Specjalistycznego im. J. Śniadeckiego w Nowym Sączu, Oddział Psychiatrii Dziecięcej Wojewódzkiego Specjalistycznego Szpitala Dziecięcego im. św. Ludwika w Krakowie, Stowarzyszenie SIEMACHA czy Poradnia Psychologiczno – Pedagogiczna w Nowym Sączu.
        </p>
        <p className="abouttext">
        Prowadzę terapię dzieci, młodzieży i osób dorosłych, wspieram także rodziców doświadczających trudności wychowawczych. Pracuję z pacjentami z diagnozą zaburzeń nastroju, lękowych, osobowości, a także osobami LGBT+. W pracy, poza klasycznymi technikami poznawczymi i behawioralnymi, wykorzystuję elementy terapii dialektyczno - behawioralnej. Regularnie podnoszę swoje kwalifikacje, a pracę terapeutyczną poddaję systematycznej superwizji.
        </p>
      </div>
      <div className="aboutme-finish">
        <p className="abouttext">
          Prywatnie jestem pasjonatką górskich wędrówek i dobrej kuchni.
        </p>
      </div>
    </div>
  );
}
