import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./Main.css";

export default function App() {
  return (
    <div className="main">
      <MDBContainer>
        <MDBRow className="mb-3 g-2">
          <MDBCol className="cardd-text-center" size="12" lg="12">
            <h2>W CZYM SIĘ SPECJALIZUJĘ</h2>
          </MDBCol>
        </MDBRow>
        <MDBRow className="g-2">
          <MDBCol
            size="12"
            sm="12"
            md="6"
            lg="3"
            className="cardd rounded-8 shadow-4 p-3"
          >
            <h5 className="cardd-title">Psychoterapia indywidualna</h5>
            <p>
            Psychoterapia jest formą pomocy osobom borykającym się z problemami natury psychicznej lub kryzysem życiowym.
            </p>
            {/* <p className="cardd-arrow">⋙</p> */}
          </MDBCol>
          <MDBCol
            size="12"
            sm="12"
            md="6"
            lg="3"
            className="cardd rounded-8 shadow-4 p-3"
          >
            <h5 className="cardd-title">Konsultacje psychologiczne</h5>
            <p>
            Konsultacje mogą być dobrym rozwiązaniem dla osób, które rozważają podjęcie psychoterapii i zastanawiają się, jaka forma pomocy będzie w ich przypadku możliwa i skuteczna.
            </p>
            {/* <p className="cardd-arrow">⋙</p> */}
          </MDBCol>
          <MDBCol
            size="12"
            sm="12"
            md="6"
            lg="3"
            className="cardd rounded-8 shadow-4 p-3"
          >
            <h5 className="cardd-title">Warsztaty dla rodziców</h5>
            <p>
            Warsztaty dla rodziców w oparciu o Pozytywną Dyscyplinę – metodę wychowawczą wspierającą rozwój sprawczości, odpowiedzialności i samodzielności u dzieci w każdym wieku.
            </p>
            {/* <p className="cardd-arrow">⋙</p> */}
          </MDBCol>
          <MDBCol
            size="12"
            sm="12"
            md="6"
            lg="3"
            className="cardd rounded-8 shadow-4 p-3 cardd-padding"
          >
            <h5 className="cardd-title">Warsztaty dla dzieci i młodzieży</h5>
            <p>
            Warsztaty profilaktyczne, rozwijające kompetencje społeczne, wspierające rozwój emocjonalny – program ustalany w zależności od potrzeb grupy.
            </p>
            {/* <p className="cardd-arrow">⋙</p> */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
