import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import "./Popup.css";

export default function Popup(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <MDBBtn
          color="secondary"
          // <button
          onClick={() => props.setTrigger(false)}
          className="close-btn"
        >
          ✕
          {/* </button> */}
        </MDBBtn>

        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
